import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';
import { API_URL } from 'data/services/apiConstants';
import { usePageTitle } from 'utils/pageTitle';
import DashboardLayout from 'pages/_rd_dashboard/DashboardLayout';
import PortfolioPropertyCard from 'pages/_rd_dashboard/PortfolioPropertyCard';
// import BuildingCard from 'pages/_rd_dashboard/BuildingCard';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
import { fetchPortfolio } from 'reducers/portfolioReducer';
import { removeProperty } from 'reducers/portfolioSettingsReducer';
import { closeModal } from 'reducers/modalReducer';

import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';

import './Portfolio.css';

const Portfolio = (props) => {
	usePageTitle('Portfolio');
	const dispatch = useDispatch();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	let {
		userId,
		portfolio,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		userId: state.user.userData?.id,
		portfolio: state.portfolio.portfolioData?.data || [],
		isLoading: state.portfolio.isLoading || state.portfolioSettings.isRemovingProperty,
		currentPage: state.portfolio.portfolioData?.current_page,
		totalPages: state.portfolio.portfolioData?.last_page,
	}));


	const openNotificationModal = (portfolioId, property) => {
		dispatch(openModal(MODAL_TYPES.PROPERTY_NOTIFICATION_MODAL, { portfolioId, property }));
	}


	const confirmRemove = (propertyId) => {
		return dispatch(openModal(MODAL_TYPES.CONFIRM, { 
			color: 'danger',
			title: 'Remove from Portfolio',
			onConfirm: () => {
				dispatch(removeProperty(propertyId))
				.then((res) => {
					dispatch(fetchPortfolio(userId));
					dispatch(closeModal());
				});
			},
			children: (<div>Are you sure you want to remove this property from your portfolio?</div>),
			confirmLabel: 'Remove',
		}));
	};

	// const handleRemoveProperty = (propertyId) => {
	// 	dispatch(removeProperty(propertyId))
	// 	.then((res) => {
	// 		dispatch(fetchPortfolio(userId));
	// 		dispatch(closeModal());
	// 	});
	// }

	useEffect(() => {
		dispatch(fetchPortfolio(userId));
	}, [userId, dispatch]);

	const onExpand = (src) => {
		dispatch(openModal(MODAL_TYPES.IMAGE, { src }));
	};

	if (isMobile || true) {
		return (
			<DashboardLayout>
				<h1>Portfolio</h1>
				{isLoading ? <Spinner /> :
					<div>
						{portfolio?.map((item) => (<PortfolioPropertyCard property={item?.property} portfolio={item} />))}
					</div>
				}
			</DashboardLayout>
		);
	}


	return (
		<DashboardLayout>
			<div className="dashboard-portfolio">
				<h1>Portfolio</h1>
				{isLoading ? <Spinner /> :
					<div className="pb-4">
						<div className="dashboard-table-wrapper">
							<table className="table table-striped">
								<thead><tr>
									<th />
									<th>Property Address</th>
									<th>BIN</th>
									<th>Block</th>
									<th>Lot</th>
									<th>Settings</th>
								</tr></thead>
								<tbody>
									{(!portfolio || !portfolio.length) && (
										<tr><td colSpan={5}>Your portfolio is empty</td></tr>
									)}
									{portfolio?.map((item) => (
										<tr key={item.id}>
											<td className="dashboard-thumb-cell">
												<img
													className="thumb-small"
													src={`${API_URL}${item.property?.thumbnail_url}`}
													onClick={() => onExpand(`${API_URL}${item.property?.thumbnail_url}`)}
													alt="Building"
												/>
											</td>
											<td className="dashboard-table-property-address-cell">
												{item.property?.address ? (
													<Link to={`/property/${item.property?.address}`}>{item.property?.address}</Link>
												) : '-'}
											</td>
											<td>{item.property?.bin || '-'}</td>
											<td>{item.property?.block || '-'}</td>
											<td>{item.property?.lot || '-'}</td>
											<td className="dashboard-table-buttons-cell">
												<button className="btn btn-danger btn-sm me-3" onClick={() => confirmRemove(item.property.id)}>Remove</button>
												<button className="btn btn-primary btn-sm" onClick={() => openNotificationModal(item.id, item.property)}>Notifications</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<Paginator
							currentPage={currentPage}
							totalPages={totalPages}
							setPage={(pageNum) => dispatch(fetchPortfolio(userId, 'address|asc', pageNum, 10))}
						/>
					</div>
					
				}

			</div>
		</DashboardLayout>
	);	


}

export default Portfolio;
