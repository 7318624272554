import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

// import { closeDataSet } from 'reducers/overviewDataSetReducer';

import { actions } from 'data/store';
import { dataConfig } from 'data/dataConfig';
import { prettifyDataSource } from 'data/dataSourceConstants';
import DataTable from 'components/dataTable/DataTable';
import FilterButtons from 'components/dataTable/FilterButtons';
import Paginator from 'components/Paginator';
import Spinner from 'components/Spinner';
import OutboundLink from 'components/OutboundLink';

import './DataTableContent.css';


function DataTableContent(props) {
	const dispatch = useDispatch();
	const { dataSet, reducerName, dataSource, title, columns } = props;
	let {
		propertyId,
		isLoading,
		data,
		currentPage,
		totalPages,
		filteredStatus,
		sortField,
		sortDirection,
		numOpen,
		numClosed,
		dataSetUrl,
		propertyData,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		isLoading: state[reducerName].isLoading || state.property.isLoading || state.address.isLoading,
		data: state[reducerName].data,
		currentPage: state[reducerName].currentPage,
		totalPages: state[reducerName].totalPages,
		filteredStatus: state[reducerName].filteredStatus,
		sortField: state[reducerName].sortField,
		sortDirection: state[reducerName].sortDirection,
		numOpen: state[reducerName].numOpen,
		numClosed: state[reducerName].numClosed,
		dataSetUrl: state[reducerName].dataSetUrl,
		propertyData: state.property.propertyData,
	}));
	let [searchString, setSearchString] = useState('');

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (propertyId) {
			dispatch(actions[reducerName].fetchData(propertyId, 'open'));
		}
	}, [propertyId, reducerName]);

	let subtitle = `(${prettifyDataSource(dataSource)})`;
	let showStatusFilter = dataConfig[dataSet].hasStatus !== false;

	const debouncedSearch = debounce((searchValue) => {
		setSearchString(searchValue);
		dispatch(actions[reducerName].fetchData(propertyId, filteredStatus, 1, searchValue));
	}, 400);


	const openInModal = () => {
		let options = { dataSet, reducerName, dataSource, title, columns };
		dispatch(openModal(MODAL_TYPES.DATA_TABLE, options));
		// dispatch(openModal(MODAL_TYPES.GET_STARTED));
	};

	const onSort = (nextSort) => {
		console.log('nextSort', nextSort)
		return dispatch(actions[reducerName].fetchData(propertyId, filteredStatus, 1, searchString, nextSort));
	};

	if (propertyData && isLoading) {
		return <Spinner />;
	}

	return (
		<div className="rd-overview-data-table-content">
			{/*<div className="d-flex flex-row-reverse open-close-btns">
				<button className="btn btn-hyperlink" onClick={() => dispatch(closeDataSet())}><i className="bi bi-x-lg" /></button>
				<button className="btn btn-hyperlink mx-2" onClick={() => openInModal()}><i className="bi bi-fullscreen" /></button>
			</div>*/}
			<div>
				<div className={`data-table-container`}>
					<nav className="data-table-card-header">
						<div className="d-flex justify-content-between">
							<div>
								<span className="navbar-brand data-set-name">{title}</span>
								<span className="navbar-text data-source-name"><OutboundLink url={dataSetUrl}>{subtitle}</OutboundLink></span>
							</div>
							<div>
								<input
									type="text"
									className="form-control data-table-search me-3"
									placeholder={`Search ${title}`}
									onChange={(e) => debouncedSearch(e.target.value)}
								/>
							</div>
							<div className="table-controls">
								{showStatusFilter &&
									<FilterButtons
										onFilter={(status) => dispatch(actions[reducerName].fetchData(propertyId, status, 1, searchString))}
										filteredStatus={filteredStatus}
										numOpen={numOpen}
										numClosed={numClosed}
									/>
								}
								<button className="btn btn-hyperlink ms-3" onClick={() => openInModal()}><i className="bi bi-fullscreen" /></button>
							</div>
						</div>
					</nav>
					<div className="table-wrapper">
						<DataTable
							columns={columns}
							data={data}
							dataSet={dataSet}
							dataSource={dataSource}
							propertyData={propertyData}
							isLoading={isLoading}
							searchString={searchString}
							sortField={sortField}
							sortDirection={sortDirection}
							onSort={onSort}
						/>
					</div>
					<Paginator
						currentPage={currentPage}
						totalPages={totalPages}
						setPage={(pageNum) => dispatch(actions[reducerName].setPage(pageNum))}
					/>
				</div>


			</div>
		</div>
	);
}

export default DataTableContent;
