import { useSelector } from 'react-redux';

import dataTables from 'view/dataTables';
import { dataConfig } from 'data/dataConfig';
import { DATA_SOURCES, getAppRoute } from 'data/dataSourceConstants';

import { getLabel } from 'utils/dataSetLinkUtils';
import Badge from 'components/Badge';


import './DataSetsList.css';


function getGrouping(dataSet, config) {
	if (config.dataCategory === 'violation') {
		if (config.grouping) {
			return config.grouping;
		}
		return config.title;
	}
	return config.title;
}

function getLinks(dataCategory, propertyData) {
	let items = [];
	Object.keys(dataTables).forEach((dataSet) => {
		let config = dataTables[dataSet];

		//data source sub-loop
		DATA_SOURCES.forEach((dataSource) => {
			if (config[dataSource]) {
				let openIssueCountField = dataConfig[dataSet]?.openIssueCountFields[dataSource];
				let openIssueCount = propertyData[openIssueCountField] || 0;
				let label = getLabel(config, dataSource);
				let dataSetRoute = getAppRoute(dataSet, dataSource);
				if (config.dataCategory === dataCategory) {
					items.push({
						url: dataSetRoute,
						label: label,
						grouping: getGrouping(dataSet, config),
						config,
						dataSet,
						dataSource,
						openIssueCount,
						dataConfig: dataConfig[dataSet]
					});
				}
			}
		});
	});
	return items;
}


function DataSetsList({ category, onSelectDataSet }) {
	let propertyData = useSelector(state => state.property.propertyData || {});
	let items = getLinks(category, propertyData);

	return (
		<div className="data-sets-list">
			{items.map(item => (
				<div key={item.url} className="data-set-link-wrapper">
					<button className="btn-hyperlink" onClick={() => onSelectDataSet(item)}>
						<span>
								<span>{item.label}</span>
							{item.openIssueCount > 0 && <span className="ms-2 open-issue-badge-wrapper"><Badge count={item.openIssueCount} color={'danger'} /></span>}
						</span>
					</button>
				</div>
			))}
		</div>
	);
}

export default DataSetsList;
